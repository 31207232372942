.ql-snow .ql-tooltip {
  z-index: 50;
}

.ql-symbols {
  min-width: 50px;
  background-image: url('../../icons/symbol.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.ql-picker-item[data-value='≈'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/approx_equal.svg');
}

.ql-picker-item[data-value='°C'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/celsius.svg');
}

.ql-picker-item[data-value='«'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/arrow_brackets_start.svg');
}

.ql-picker-item[data-value='»'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/arrow_brackets_end.svg');
}

.ql-picker-item[data-value='„'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/quotes_start.svg');
}

.ql-picker-item[data-value='“'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/quotes_end.svg');
}

.ql-picker-item[data-value='₽'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/rub.svg');
}

.ql-picker-item[data-value='—'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/long-dash.svg');
}

.ql-picker-item[data-value='–'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/short-dash.svg');
}

.ql-picker-item[data-value='−'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 18px;
  background-image: url('../../icons/minus.svg');
}
