.pageContainer {
  margin-top: 40px;
  display: flex;
  width: 100%;
  max-width: 1200px;
}

.pageContainerMobile {
  justify-content: center;
}

.workspace {
  composes: workspace from '../Tasks/Table/TaskTable.module.css';
  max-width: 850px;
}

.workspaceMobile {
  max-width: 600px;
}

.title {
  font-size: 18px;
  font-family: var(--font-bold);
  overflow-wrap: break-word;
  max-width: 90vw;
  margin: 30px 0 10px;
}

.workspace pre {
  white-space: pre-line;
  word-break: break-word;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowDetails {
  composes: row;
  margin-bottom: 10px;
}

.rowDetails:first-child {
  margin-top: 28px;
}

.auditorsRow {
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
}

.time {
  color: #616161;
  font-size: 14px;
}

.info {
  color: #616161;
  font-size: 18px;
}

.infoTitle {
  color: var(--blue);
}

.auditorsTitle {
  composes: infoTitle;
  margin-bottom: 10px;
}

.infoDescription {
  text-align: right;
}

.changeStatusButton {
  width: 100%;
}

.finishTaskButton {
  composes: changeStatusButton;
}

.finishTaskButton:enabled {
  background-color: var(--green);
}

.cancelTaskButton:enabled {
  background-color: var(--red);
}

.modalContent {
  min-width: 260px;
  min-height: 180px;
}

.centerContainer {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.commentsSection {
  margin-top: 27px;
}

.newCommentSection {
  margin-top: 30px;
}

.buttonLink {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.buttonContainer button {
  margin: 5px 10px;
}

.mainInfoContainer {
  composes: row;
  margin-bottom: 25px;
  align-items: center;
}

.statusContainer {
  display: flex;
}

.status {
  margin-top: 0;
}

.attachedFiles {
  margin-top: 10px;
  margin-bottom: 10px;
}

.spinner {
  margin-top: 20px;
  margin-bottom: 10px;
}

.pageSpinner {
  composes: spinner;
  margin-top: 50px;
}

.currentlyWriting {
  margin-top: 20px;
  font-size: 15px;
}

.addComment {
  margin-bottom: 0.5rem;
}

.commentFileInput {
  margin-left: 0;
}

.editButton {
  composes: customButton from '../../components/base/Base.module.css';
  --size: 27px;
  background-image: url('../../icons/edit.svg');
  background-position: center;
  margin-top: 4px;
  margin-left: 4px;
  cursor: pointer;
}

.taskDetails {
  font-size: 14px;
  margin-left: 14px;
}

.infoSpinner {
  height: 40px;
  width: 40px;
  margin-left: 0;
}

@media (max-width: 1000px) {
  .buttonContainer {
    flex-direction: column;
  }
}

@media (hover: hover) {
  .finishTaskButton:hover:enabled {
    color: var(--green);
    border: 3px solid var(--green);
  }

  .cancelTaskButton:hover:enabled {
    color: var(--red);
    border: 3px solid var(--red);
  }
}
