.button {
  padding: 0.5rem 1.25rem;
  min-width: 0;
  white-space: pre;
}

.sidebar {
  margin-right: 1.5rem;
  height: max-content;
  position: sticky;
  top: 10px;
}

.filterLink {
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
  display: block;
  color: black;
  text-decoration: none;
  margin: 1rem 0;
}

.filterLink:hover {
  text-decoration: underline;
}

.filterLinkCurrent {
  composes: filterLink;
  text-decoration: underline;
  color: var(--blue);
}

@media (hover: hover) {
  .button:hover:enabled {
    padding: calc(0.5rem - 3px) calc(1.25rem - 3px);
  }
}
