.search {
  position: absolute;
  left: 20px;
  width: calc(100% - 2 * 20px);
  font-size: 20px;
  padding: 5px 0;
  box-sizing: border-box;
  margin: 0;
  border: none;
  border-bottom: 1px solid #dadada;
}
