.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.filter {
  color: #1a204a;
  font-family: var(--font-bold);
  font-size: 20px;
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 0 42px;
  white-space: pre;
}

.filterSkeleton {
  background: var(--skeleton-gray);
  height: 26px;
  width: 30%;
}

.filter:first-child {
  margin-left: 0;
}

.filter:hover {
  text-decoration: underline;
}

.filterCurrent {
  composes: filter;
  text-decoration: underline;
}

.moreFiltersContainer {
  position: relative;
  margin: 0 0 0 42px;
}

.moreButton {
  composes: filter;
}

.moreIcon {
  margin-left: 5px;
  margin-bottom: 2px;
}

.dropdown {
  padding: 0.5rem 0.8rem;
  background: white;
  border: 1px solid #b4b4b4;
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 100;
  cursor: default;
  border-radius: 2px;
  transform-origin: top right;
}

.dropdownButton {
  composes: filter;
  display: block;
  margin-left: 0;
  margin-bottom: 6px;
  font-size: 16px;
}

.dropdownButton:last-child {
  margin-bottom: 0;
}
