.container {
  display: flex;
}

.header {
  box-sizing: border-box;
  width: 100%;
  height: 63px;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background: white;
  z-index: 200;
}

@media (min-width: 1000px) {
  .header {
    padding: 0 40px;
  }
}

.sideBlock {
  flex: 2;
}

.logo {
  display: block;
  height: 2rem;
  margin: 0 1.5rem;
  cursor: pointer;
}

.buttonBase {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  margin: 0;
  padding: 0;
  border: none;
  appearance: none;
  cursor: pointer;
}

.backButton {
  composes: buttonBase;
  background-image: url(../icons/back.svg);
}

.menu {
  box-sizing: border-box;
  width: 350px;
  height: 100vh;
  background-color: #19204a;
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 40px 50px;
  outline: none;
  right: 0;
}

.menuEnter {
  transform: translateX(450px);
}

.menuEnterActive {
  transition: transform 300ms;
  transform: translateX(0px);
}

.menuExit {
  transform: translateX(0px);
}

.menuExitActive {
  transition: transform 300ms;
  transform: translateX(450px);
}

.menuItem {
  display: block;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 18px;
  font-family: var(--font-bold);
  margin-bottom: 1rem;
}

.menuItem:hover {
  text-decoration: underline;
}

.searchButton {
  composes: menuItem;
  background-image: url(../icons/magnifier_white.svg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 2rem;
}

.logoutButton {
  composes: menuItem;
  appearance: none;
  background-image: url(../icons/logout.svg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  margin: 0;
  padding: 0;
  padding-left: 2rem;
  border: none;
  cursor: pointer;
}

.tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  background: rgba(25, 32, 74, 0.3);
}

.tintEnter {
  opacity: 0;
}

.tintEnterActive {
  transition: opacity 300ms;
  opacity: 1;
}

.tintExit {
  opacity: 1;
}

.tintExitActive {
  transition: opacity 300ms;
  opacity: 0;
}

.oldVersionLink {
  appearance: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: white;
  margin-top: 10px;
}

.oldVersionLink:hover {
  text-decoration: underline;
}

.rightSideBlock {
  composes: sideBlock;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.spinner {
  margin-top: 20px;
}

.progress {
  width: 100%;
  height: 3px;
  background: var(--blue);
  position: absolute;
  left: 0;
  bottom: -3px;
  transform-origin: left;
  animation: makeProgress 3s;
}

@keyframes makeProgress {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@media (max-width: 600px) {
  .header {
    z-index: 900;
  }

  .menu {
    position: absolute;
    top: 63px;
    width: 100vw;
    height: calc(100vh - 63px);
    transition: none;
    z-index: 100;
  }

  .tint {
    display: none;
  }

  .menuEnter {
    transform: translateY(-100%);
  }

  .menuEnterActive {
    transition: transform 300ms;
    transform: translateY(0%);
  }

  .menuExit {
    transform: translateY(0%);
  }

  .menuExitActive {
    transition: transform 300ms;
    transform: translateY(-100%);
  }
}
