.item {
  margin-top: 20px;
}

.avatar {
  margin-right: 10px;
  min-width: 38px;
  min-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c4c4c4;
  color: white;
  font-size: 25px;
  border-radius: 20px;
}

.comment {
  composes: description from 'DescriptionHtml.module.css';
  background-color: #e5e5e5;
  color: #050505;
  font-size: 15px;
  border-radius: 8px 8px 8px 0;
  padding: 8px 12px;
}

.commentInternal {
  composes: comment;
  background-color: #c4c4c4;
}

.comment p:first-child {
  margin-top: 0;
}

.comment p:last-child {
  margin-bottom: 0;
}

.triangle {
  position: absolute;
  transform: translate(-6px, -7px);
  background-color: #e5e5e5;
  width: 7px;
  height: 7px;
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
}

.triangleInternal {
  composes: triangle;
  background-color: #c4c4c4;
}

.userComment {
  background-color: var(--blue);
  color: white;
}

.info {
  color: #858e99;
  font-size: 11px;
  padding: 0 12px 2px;
  position: relative;
}

.internalCommentImage {
  display: block;
  --size: 9px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  left: 0;
  bottom: 4px;
}

.container {
  display: flex;
  align-items: flex-end;
}

.attachedFiles {
  margin-left: 48px;
  margin-top: 10px;
}
