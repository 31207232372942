.title {
  font-family: var(--font-bold);
  font-size: 2rem;
  margin: 1rem 0;
}

@media (max-width: 600px) {
  .title {
    width: 100%;
  }
}
