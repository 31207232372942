.wrapper {
  height: auto;
  min-height: 200px;
  padding: 0;
}

.wrapper > div:first-child,
.wrapper > div:last-child {
  border: none;
}

.wrapper div[contenteditable='true'],
.wrapper div[contenteditable='false'] {
  font-size: 1rem;
  font-family: var(--font-normal);
  word-break: break-word;
}

.wrapper div[contenteditable='true']::before {
  font-style: normal;
  color: var(--gray);
}

.toolbar {
  border-bottom: none !important;
}
