.detailsEnter {
  max-height: 0;
  overflow: auto;
}

.detailsEnterActive {
  max-height: 150px;
  transition: max-height 300ms;
  overflow: auto;
}

.detailsExit {
  max-height: 150px;
  overflow: hidden;
}

.detailsExitActive {
  max-height: 0;
  transition: max-height 300ms;
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.info {
  color: #616161;
  font-size: 18px;
}

.blue {
  color: var(--blue);
}

.line {
  margin: 1rem 0;
  opacity: 0.7;
}

.expandButton {
  composes: customButton from './Base.module.css';
}
