.control {
  position: relative;
  display: flex;

  align-items: baseline;
  margin-bottom: 1rem;
}

.label {
  order: 1;
  flex-basis: 183px;
  flex-shrink: 0;
}

.inputContainer {
  order: 2;
  flex-grow: 1;
  position: relative;
}

.controlInput {
  width: 100%;
  height: 40px;
  border: 1px solid #dadada;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0.5rem;
  margin: 0;
}

.controlInputInvalid {
  border: 1px solid var(--red);
}

.controlInputDisabled {
  background-color: rgb(235, 235, 235);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray);
  opacity: 1; /* Firefox */
}

.placeholder {
  color: var(--gray);
}

select.controlInput {
  background-image: url(../../icons/arrow.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
  background-color: white;
}

textarea.controlInput {
  resize: vertical;
  height: 12rem;
  min-height: 6rem;
}

.customButton {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  width: var(--size);
  height: var(--size);
}

.deleteButton {
  composes: customButton;
  --size: 11px;
  background-image: url(../../icons/delete.svg);
  margin-right: 30px;
}

.requiredMark {
  color: #ff6059;
  margin-left: 3px;
}

.hintButton {
  composes: customButton;
  --size: 14px;
  background-image: url(../../icons/hint.svg);
  order: 3;
  position: absolute;
  right: -25px;
  z-index: 20;
  margin-top: 13px;
}

.hintContainer {
  position: absolute;
  right: -18px;
  top: 30px;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.hint {
  background-color: #dadada;
  color: #616161;
  font-size: 13px;
  max-width: 200px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px 0 5px 5px;
}

.hintTriangle {
  width: 10px;
  height: 10px;
  clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
  background-color: #dadada;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.validationMessage {
  color: var(--red);
  flex-basis: 100%;
  padding-top: 0.3rem;
  font-size: 16px;
}

.skeleton {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.75) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    var(--skeleton-gray);
  background-repeat: repeat-y;
  background-size: 50px 200px;
  background-position: 0 0;
  animation: shine 1.5s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}

@media (max-width: 600px) {
  .control {
    align-items: center;
    flex-wrap: wrap;
  }

  .inputContainer {
    flex-basis: 100%;
  }

  .label {
    flex-grow: 99;
    margin: 0 0 0.5rem 0;
  }

  .hintButton {
    order: 2;
    position: static;
    right: 0;
    margin-top: 0;
    padding: 0.5rem;
    box-sizing: content-box;
  }

  .hintContainer {
    top: 29px;
    right: 15px;
  }
}
