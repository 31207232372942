.spinner {
  margin-top: 100px;
}

.restoreButton {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0 auto;
  font-family: var(--font-book);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--blue);
  border-bottom: 1px dashed var(--blue);
}

.reglamentText {
  border: 0;
  padding: 0;
  margin: 0;
  color: var(--gray-text);
}

.reglamentButton {
  composes: restoreButton;
  color: var(--gray-text);
  border-bottom: 1px dashed var(--gray-text);
}

.contractCount {
  font-size: 16px;
  border: 0;
  padding: 0;
  margin: -15px 0 -20px;
  text-align: right;
  position: relative;
}

.contractCountHint {
  border: 0;
  position: absolute;
  top: -32px;
  left: -7px;
}
