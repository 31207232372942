.search {
  background-image: url(../../../icons/add.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
  min-width: 312px;
  outline: none;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  padding-right: 25px;
}

.search::placeholder {
  color: #3158ff;
}

.selectedList {
  display: flex;
  flex-wrap: wrap;
}

.selected {
  background-color: #3158ff;
  color: white;
  padding: 5px 10px;
  margin: 3px;
  width: max-content;
  white-space: pre;
  display: flex;
  align-items: center;
  cursor: default;
  user-select: none;
  outline: none;
}

.selectedText {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete {
  composes: customButton from '../Base.module.css';
  --size: 11px;
  background-image: url(../../../icons/delete_white.svg);
  margin-left: 12px;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #3158ff;
  box-sizing: border-box;
  width: 100%;
  max-height: 230px;
  overflow-x: hidden;
  overflow-y: scroll;
  outline: none;
  z-index: 10;
}

.listItem {
  color: #616161;
  padding: 11px 12px;
  cursor: pointer;
  user-select: none;
}

.listInfoItem {
  composes: listItem;
  cursor: default;
  color: darkgray;
}

.listItem:hover {
  background-color: rgb(250, 250, 250);
}

.queryInputMulti {
  outline: none;
  border: none;
  background-color: transparent;
  padding: 3px;
  height: auto;
  width: max-content;
}

/* ---------------------- */

.singleSearch {
  composes: search;
  position: relative;
}

.singleSearch--selected {
  background-image: none;
}

.singleSearch--disabled {
  background-color: rgb(235, 235, 235);
}

.queryInput {
  composes: queryInputMulti;
  width: 100%;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  max-width: 360px;
  text-overflow: ellipsis;
}

.queryInput--selected::placeholder {
  color: black;
}

@media (max-width: 600px) {
  .queryInput {
    max-width: 290px;
  }
}

.resetButton {
  composes: customButton from '../Base.module.css';
  cursor: pointer;
  --size: 12px;
  background-image: url(../../../icons/delete_blue.svg);
  position: absolute;
  right: 10px;
  top: calc(50% - var(--size) / 2);
}

.resetButton:disabled {
  cursor: default;
}
