.container {
  padding: 30px 15px;
  max-width: 650px;
  margin: auto;
}

.logo {
  display: block;
  margin: auto;
}

.title {
  font-family: var(--font-bold);
}
