.pageContainer {
  margin-top: 40px;
  display: block;
  width: 654px;
  height: fit-content;
}

.back {
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  letter-spacing: -3.5638e-5em;
  color: #000000;
}
.backArrow {
  cursor: pointer;
  display: inline-block;
  background-image: url(../../icons/arrow-left.svg);
  width: 10px;
  height: 10px;
  appearance: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  color: transparent;
  margin-right: 3px;
}

.pageContainerMobile {
  justify-content: center;
  width: 100%;
}

.date {
  display: block;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 113.85%;
  color: #979797;
}
.text {
  display: block;
  font-size: 14px;
  word-wrap: break-word;
}
.space {
  display: block;
  height: 20px;
  width: 100%;
}

.row {
}
.rowUnread {
  background-color: rgba(196, 196, 196, 0.2);
}

.paginationBlock {
  display: inline-block;
  margin: auto;
  width: 100%;
  text-align: center;
}
.leftArrow {
  composes: backArrow;
  transform: rotate(0deg);
  margin-right: 20px;
}

.rightArrow {
  composes: backArrow;
  transform: rotate(180deg);
  margin-left: 20px;
}

.spinner {
  margin-top: 100px;
}

.markButton {
  height: 36px;
  width: 200px;
  font-size: 18px;
  margin: 20px 0;
  min-width: auto;
  padding: 0;
}

@media (hover: hover) {
  .markButton:hover:enabled {
    padding: 0;
  }
}
