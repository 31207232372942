.container {
  margin-right: 20px;
}

.button {
  composes: skeleton from '../../components/base/Base.module.css';
  height: 46px;
  width: 187px;
  margin-bottom: 14px;
}

.filter {
  composes: skeleton from '../../components/base/Base.module.css';
  height: 22px;
  width: 75%;
  margin-bottom: 15px;
}
