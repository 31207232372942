.button {
  font-size: 1.2rem;
  color: white;
  background-color: var(--blue);
  display: block;
  border: 0;
  margin: auto;
  padding: 0.5rem 44px;
  min-width: 220px;
}

.disabled {
  background-color: var(--gray);
}

.loading {
  background-color: var(--gray);
  background-image: url(../../icons/spinner_button.svg);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: left 15px top 50%;
}

.done {
  background-color: var(--green);
  background-image: url(../../icons/confirmed.svg);
  background-size: 18px;
  padding-right: calc(44px + 18px + 10px);
  background-repeat: no-repeat;
  background-position: right 44px top 50%;
}

@media (hover: hover) {
  .button:hover:enabled {
    background-color: white;
    color: var(--blue);
    border: 3px solid var(--blue);
    padding: calc(0.5rem - 3px) calc(44px - 3px);
    box-shadow: none;
    cursor: pointer;
  }
}
