.container {
  position: relative;
}

.search {
  box-sizing: border-box;
  padding: 3px 50px 3px 20px;
  font-size: 20px;
  margin: 0;
  border: 1px solid #dadada;
  background-image: url(../icons/magnifier.svg);
  background-repeat: no-repeat;
  background-position: bottom 50% right 12px;
  background-size: 21px;

  min-width: 400px;
}

.search::placeholder {
  color: #616161;
}

.loading {
  background-image: url(../icons/spinner.svg);
}

.results {
  width: 100%;
  position: absolute;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #dadada;
  border-top: none;
  z-index: 300;
}

@media (max-width: 600px) {
  .results {
    display: fixed;
    top: 0;
    left: 0;
    border: none;
    height: 100vh;
    width: 100vw;
  }
}

.result {
  color: black;
  text-decoration: none;
  display: block;
  padding: 10px 20px;
}

.loadMoreButton {
  composes: result;
  color: var(--blue);
  margin: 0;
  border: none;
  appearance: none;
  font-size: 18px;
  font-family: var(--font-bold);
  background: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}

.result:hover {
  background-color: rgb(250, 250, 250);
}

.result > b {
  font-family: var(--font-bold);
}
