.container {
  margin-bottom: 20px;
}

.titleContainer {
  height: 12px;
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
}

.project {
  composes: skeleton from '../../../components/base/Base.module.css';
  flex-basis: 156px;
  height: 100%;
}

.date {
  composes: skeleton from '../../../components/base/Base.module.css';
  flex-basis: 88px;
  height: 100%;
}

.title {
  composes: skeleton from '../../../components/base/Base.module.css';
  width: 60%;
  height: 18px;
  margin-bottom: 11px;
}

.status {
  composes: skeleton from '../../../components/base/Base.module.css';
  width: 25%;
  height: 16px;
}
