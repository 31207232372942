.pageContainer {
  width: 100%;
  max-width: 1200px;
}

.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerTitle {
  text-align: left;
  border-bottom: 1px solid #979797;
  padding: 0 0 5px 1rem;
  white-space: pre;
}

.headerTitle:first-child {
  padding-left: 0;
  width: 33%;
}

.headerTitle:last-child {
  padding-right: 0;
}

.sortButton {
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  display: block;
  text-decoration: none;
  margin: 0;

  color: #979797;
  font-size: 15px;
}

.currentSort {
  composes: sortButton;
  color: black;
}

.sortButton:disabled {
  cursor: default;
}

.row {
  padding: 1rem 0;
}

.row:hover {
  background-color: rgb(250, 250, 250);
}

.cell {
  padding: 0.5rem 0 0.5rem 1rem;
  color: #616161;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell:first-child {
  padding-left: 0;
}

.cell:last-child {
  padding-right: 0;
}

.taskTitleContainer {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
}

.taskTitle {
  font-size: 1rem;
  color: black;
  display: flex;
  text-decoration: none;
}

.workspace {
  flex: 2;
}

.dateCell {
  composes: cell;
  white-space: pre;
}

.table {
  min-width: 1100px;
  border-spacing: 0 0.25rem;
  width: 100%;
  table-layout: fixed;
}

.skeleton {
  width: 100%;
  height: 25px;
  composes: skeleton from '../../../components/base/Base.module.css';
}

.skeletonRow {
  display: flex;
  margin: 1rem 0;
}

.skeletonCell {
  composes: skeleton;
  margin-right: 10px;
}

.skeletonCell:last-child {
  margin-right: 0;
}

.statusBadge {
  padding: 5px 10px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tableTitleContainer .createTaskButton {
  margin: 0;
}

.noTasks {
  font-family: var(--font-bold);
  text-align: center;
}

.spinner {
  margin-top: 100px;
}

.taskCounters {
  display: block;
  font-size: 16px;
  font-family: var(--font-normal);
}

@media (max-width: 1400px) {
  .statusBadge {
    max-width: 70px;
  }
}

@media (min-width: 1401px) {
  .statusBadge {
    max-width: 80px;
  }
}

@media (min-width: 1600px) {
  .statusBadge {
    max-width: max-content;
  }
}

@media (min-width: 1900px) {
  .pageContainer {
    max-width: 1600px;
  }
}

@media (min-width: 2500px) {
  .pageContainer {
    max-width: 1800px;
  }
}
