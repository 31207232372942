.priorityContainer {
  display: flex;
  align-items: center;
}

.priority {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.priority--0 {
  composes: priority;
  background-color: #b4b4b4;
}

.priority--1 {
  composes: priority;
  background-color: #1dac2c;
}

.priority--2 {
  composes: priority;
  background-color: #f13a00;
}

.priorityTitle {
  margin-left: 10px;
}
