.outerContainer {
  flex: 1;
  outline: none;
  margin-left: 10px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  box-sizing: border-box;
}

.time {
  background-image: url(../../icons/clock.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
}

.time::-webkit-inner-spin-button {
  margin-right: 30px;
}

.time > input {
  width: 75%;
  height: 100%;
  border: none;
  font: inherit;
  background-color: inherit;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
