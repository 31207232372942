.notificationButton {
  appearance: none;
  margin-right: 10px;
  padding: 0;
  border: none;
  background-color: transparent;
  background-position: center;
  background-image: url('../../icons/bell.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 24px;
  width: 26px;
  cursor: pointer;
}

.unreadNotificationButton {
  composes: notificationButton;
  background-image: url('../../icons/bell-new.svg');
}
