@keyframes reveal {
  from {
    transform: scaleY(0);
  }
}

.alert {
  background-color: var(--red);
  display: inline-block;
  justify-content: center;
  box-sizing: border-box;
  positions: relative;
  width: 100%;
  padding: 0 20px;
  animation: reveal 0.5s;
  margin-top: 63px;
}

.container {
  width: 100%;
  max-width: 1400px;
}

.message {
  font-size: 0.83rem;
  color: white;
  background-image: url(../../icons/alert.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 0;
  display: flex;
  align-items: center;
  padding: 7px 0;
  white-space: pre;
}
