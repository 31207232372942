.arrow {
  composes: customButton from '../Base.module.css';
  cursor: pointer;
}

.prev {
  composes: arrow;
}

.prev::before {
  color: white;
  content: '\2190';
}

.next {
  composes: arrow;
}

.next::before {
  color: white;
  content: '\2192';
}

.day {
  composes: customButton from '../Base.module.css';
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgray;
  font-size: 18px;
}

.currentMonth {
  color: black;
  cursor: pointer;
}

@media (hover: hover) {
  .currentMonth:hover {
    background-color: var(--blue);
    color: white;
  }
}

.weekend {
  color: hotpink;
}

.selected {
  background-color: var(--blue);
  color: white;
}

.dayName {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue);
}

.picker {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  z-index: 999;
  display: grid;
  grid-template-rows: 30px 200px;
}

.pickerButton {
  composes: customButton from '../Base.module.css';
  width: 80%;
  margin-right: 10px;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
}

.pickerButton:active {
  color: black;
}

.title {
  box-sizing: border-box;
  background-color: var(--blue);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title:first-of-type {
  border-right: 1px solid var(--gray);
  justify-content: space-between;
  padding: 0 10px;
}

.calendar {
  border: 1px solid var(--gray);
  border-top: none;
  box-sizing: border-box;
  background-color: whitesmoke;
  display: grid;
  grid-gap: 2px;
  padding: 2px;
  grid-template-columns: repeat(7, 1fr);
}

.input {
  background-image: url(../../../icons/calendar.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.outerContainer {
  flex: 1;
  outline: none;
}
