.imgSelect {
  appearance: none;
  background-color: transparent;
  background-image: url(../../../icons/arrows.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 40px;
  margin-left: 10px;
  font-size: 1em;
  overflow: hidden;
  color: transparent;
}

.imgSelect option {
  color: black;
}

.imgSelect option:disabled {
  color: lightgray;
}

.taskLink {
  text-decoration: none;
}

.plusButton {
  width: 75px;
  height: 75px;
  background-color: transparent;
  background-image: url(../../../icons/plus_circle.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
}

.noItems {
  font-family: var(--font-bold);
  text-align: center;
}

.spinner {
  margin-top: 10px;
}
