@font-face {
  font-family: 'Futura New Book';
  src: url(../fonts/futura_new_book_reg.ttf) format('truetype');
}

@font-face {
  font-family: 'Futura New Bold';
  src: url(../fonts/futura_new_bold_reg.ttf) format('truetype');
}

:root {
  --blue: #3158ff;
  --gray: #b4b4b4;
  --gray-text: #979797;
  --red: #ff6059;
  --yellow: #ffbd59;
  --green: #10cb23;
  --skeleton-gray: #f2f2f2;
  --font-normal: 'Futura New Book', sans-serif;
  --font-bold: 'Futura New Bold', sans-serif;
}

html {
  font-family: var(--font-normal);
  font-size: 18px;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

body {
  padding: 0;
  margin: 0;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
  max-width: 600px;
  width: 100%;
}

hr {
  margin: 15px 0;
}

.page {
  width: 100%;
  min-height: 100vh;
  z-index: 100;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-enter {
  transform: translateX(100vw);
}

.page-enter-active {
  transform: translateX(0vw);
  transition: transform 300ms;
}

.page-exit {
  transform: translateX(0vw);
}

.page-exit-active {
  transform: translateX(100vw);
  transition: transform 300ms;
}

.ql-clipboard {
  position: fixed;
  display: none;

  left: 50%;
  top: 50%;
}
