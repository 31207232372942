.task {
  composes: task from '../Tasks/List/Task.module.css';
  grid-template-columns: 4fr 1.2fr;
  margin: 25px 0;
}

.taskTitle {
  font-size: 18px;
  margin-bottom: 2px;
  word-break: break-word;
}

.taskDescription {
  font-size: 14px;
  word-break: break-word;
}

.taskDateChanged {
  color: #616161;
  font-size: 14px;
}
