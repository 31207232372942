.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 80%;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.link {
  color: white !important;
  font-size: 24px;
  text-decoration: none;
  margin-top: 10px;
}

.link::after {
  content: url('../icons/download.svg');
  margin-left: 12px;
  vertical-align: -25%;
}
