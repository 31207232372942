.imageContainer {
  margin-left: 185px;
  margin-bottom: 1rem;
}

.image {
  display: block;
  width: 100%;
}

@media (max-width: 600px) {
  .imageContainer {
    margin-left: 0;
  }
}
