.fileContainer {
  display: flex;
  flex-wrap: wrap;
}

.file {
  text-decoration: none;
  border: 0.5px solid #858e99;
  border-radius: 9px;
  color: #8a8a8f;
  font-size: 11px;
  padding: 3px 9px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: max-content;
  white-space: pre;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
}

.fileIcon {
  margin-right: 2px;
}

.fileListTitle {
  margin: 12px 0 8px;
}

.imageButton {
  composes: customButton from '../../components/base/Base.module.css';
  cursor: pointer;
  border: 0.5px solid #858e99;
  --size: 80px;
  margin-right: 18px;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
