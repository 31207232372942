.container {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(25, 32, 74, 0.27);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100vw;
  height: 100vh;
}

.modal {
  box-sizing: border-box;
  max-width: 450px;
  background-color: white;
  z-index: 999;
  padding: 20px 20px 40px;
}

.buttonContainer {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  composes: customButton from './base/Base.module.css';
  background-image: url(../icons/close.svg);
  cursor: pointer;
  --size: 15px;
}

.modalOpen {
  /* ios 12 safari fix */
  overflow-y: hidden;
  width: 100%;
  position: fixed;
}

.blurred {
  filter: blur(2.5px);
}
