.title {
  composes: skeleton from '../../components/base/Base.module.css';
  height: 24px;
  margin-bottom: 17px;
}

.status {
  composes: skeleton from '../../components/base/Base.module.css';
  height: 17px;
  width: 20%;
  margin-bottom: 37px;
}

.description {
  composes: skeleton from '../../components/base/Base.module.css';
  height: 31px;
  margin-bottom: 40px;
}

.row {
  width: 80%;
  margin-bottom: 20px;
}

.firstLine,
.secondLine,
.thirdLine {
  height: 22px;
  margin-bottom: 5px;
  composes: skeleton from '../../components/base/Base.module.css';
}

.row div:nth-child(2) {
  width: 80%;
}

.row div:nth-child(3) {
  width: 70%;
}
