.description {
  font-size: 18px;
  white-space: pre-line;
  word-break: break-word;
}

.description a,
.description button {
  color: var(--blue);
}

.fileLink {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
}

.imgLink {
  cursor: pointer;
}
