.container {
  margin-bottom: 1rem;
}

.label {
  cursor: pointer;
}

.input {
  margin-left: 0;
  margin-right: 13px;
  cursor: pointer;
}
