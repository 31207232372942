.container {
  margin-top: 40px;
  width: 100%;
  max-width: 600px;
}

.noResults {
  font-size: 25px;
  margin-top: 50px;
}

.link {
  text-decoration: none;
  color: black;
}

.spinner {
  margin-top: 100px;
}
