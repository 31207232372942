.container {
  width: max-content;
  margin-right: auto;
  margin-left: 185px;
  margin-top: 10px;
  margin-bottom: 53px;
}

.input {
  display: flex;
  align-items: center;
  color: var(--blue);
  cursor: pointer;
  background-image: url(../../icons/plus.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 0;
  min-height: 32px;
  padding-left: 47px;
}

.input--disabled {
  cursor: not-allowed;
}

.fileContainer {
  display: flex;
  align-items: center;
  margin-left: 47px;
  margin-top: 13px;
}

.fileName {
  color: #979797;
  font-size: 0.83rem;
  text-decoration: none;
}

.deleteButton {
  composes: deleteButton from './Base.module.css';
  margin-right: 10px;
  cursor: pointer;
}

.imageContainer {
  border: 0.5px solid #858e99;
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (max-width: 600px) {
  .container {
    margin-left: 0;
    margin-bottom: 35px;
  }
}
