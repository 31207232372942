.container {
  position: relative;
  float: right;
  width: 520px;
  height: 312px;
  right: 90px;
  top: 63px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 0;
  display: block;
}

.containerHiden {
  composes: container;
  display: none;
}

.item {
  width: 100%;
}

.tab {
  position: relative;
  height: 270px;
  width: 100%;
  background: #ffffff;
  z-index: 0;
  overflow: auto;
}

.row {
  composes: item;
  height: 100px;
  font-size: 14px;
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
}

.itemContainer {
  margin: auto;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.empty {
  height: 30px;
  text-align: center;
  padding-top: 10px;
}

.link {
  composes: item;
  display: block;
  font-family: var(--font-bold);
  color: #3158ff;
  width: fit-content;
  height: 28px;
}

.linkContainer {
  background: #ffffff;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  display: flex;
  bottom: 0px;
  height: 50px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.row:hover {
  background: rgba(196, 196, 196, 0.2);
}

.date {
  display: block;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 113.85%;
  color: #979797;
}

.markButton {
  height: 36px;
  width: 200px;
  font-size: 18px;
  margin: 0;
  min-width: auto;
  padding: 0;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    right: 0;
  }
}

@media (hover: hover) {
  .markButton:hover:enabled {
    padding: 0;
  }
}
