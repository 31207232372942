.status {
  color: white;
  width: max-content;
  padding: 5px 15px;
  font-size: 14px;
  margin-top: 4px;
}

.status--NOT_PROCESSED {
  composes: status;
  background-color: var(--red);
}

.status--NEW {
  composes: status;
  background-color: var(--yellow);
}

.status--REGISTERED {
  composes: status;
  background-color: var(--yellow);
}

.status--PROGRESS {
  composes: status;
  background-color: var(--blue);
}

.status--PAUSED {
  composes: status;
  background-color: var(--gray);
}

.status--RESUMED {
  composes: status;
  background-color: #ff8a39;
}

.status--DEFERRED {
  composes: status;
  background-color: #b4b4b4;
}

.status--DECLINED {
  composes: status;
  background-color: #b4b4b4;
}

.status--COMPLETED {
  composes: status;
  background-color: #41b5a0;
}

.status--FINISHED {
  composes: status;
  background-color: var(--green);
}

.title {
  color: black;
  font-size: 18px;
  word-break: break-word;
  display: flex;
  align-items: center;
}

.project {
  box-sizing: border-box;
  padding-bottom: 8px;
  color: #616161;
  font-size: 12px;
}

.time {
  color: #616161;
  font-size: 14px;
  white-space: pre;
}

.priorityAndCommentsContainer {
  display: flex;
  justify-content: space-between;
}

.comments {
  color: white;
  background-color: #858e99;
  font-size: 14px;
  font-family: var(--font-bold);
  border-radius: 10px;
  height: max-content;
  padding: 0 5px;
  width: max-content;
  text-align: right;
  align-self: center;
  justify-self: end;
  white-space: pre;
}

.task {
  margin: 23px 0;
  display: grid;
  grid-template-columns: 3fr 1.3fr;
  column-gap: 5px;
  row-gap: 3px;
}

@media (hover: hover) {
  .task:hover {
    background-color: rgb(250, 250, 250);
  }
}
