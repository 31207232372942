.button {
  composes: customButton from './base/Base.module.css';
  cursor: pointer;
  font-size: 16px;
}

.button:active {
  color: black;
}

.button::before {
  content: url('../icons/back_button_arrow.svg');
  margin-right: 4px;
}
