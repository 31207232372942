.container {
  display: flex;
  margin-bottom: 18px;
  align-items: flex-end;
}

.avatar {
  height: 45px;
  width: 45px;
  border-radius: 45px;
  composes: skeleton from '../../components/base/Base.module.css';
  margin-right: 14px;
}

.response {
  width: 80%;
}

.date {
  height: 11px;
  width: 30%;
  composes: skeleton from '../../components/base/Base.module.css';
  margin-bottom: 4px;
}

.text {
  height: 42px;
  composes: skeleton from '../../components/base/Base.module.css';
}
