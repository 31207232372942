.container {
  height: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.line {
  background: #1a204a;
  width: 34px;
  height: 3px;
  transform: rotateZ(0deg);
  transition: transform 250ms, opacity 250ms;
  opacity: 1;
  transform-origin: left;
}

.openTop {
  transform: rotateZ(45deg) translate3d(-1.5px, -1.5px, 0);
}

.openMiddle {
  opacity: 0;
}

.openBottom {
  transform: rotateZ(-45deg) translate3d(-1.5px, 1.5px, 0);
}
