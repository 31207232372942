.spinner {
  margin-top: 100px;
}

.mainContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 63px;
}
.mainContainerAlertOn {
  composes: mainContainer;
  top: 90px;
}

@media (min-width: 1000px) {
  .mainContainer {
    padding: 0 40px 40px 40px;
  }
}
